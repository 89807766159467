import { addNavMenuItem } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem(
        {
            id: 'shipping-price',
            label: 'Shipping Price',
            routerLink: ['/extensions/shipping-price'],
            icon: 'truck',
        },
        'settings',
    ),
];
