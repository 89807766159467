import { addNavMenuItem } from '@vendure/admin-ui/core';
import { addActionBarItem, ActionBarContext, ModalService } from '@vendure/admin-ui/core';

export default [
    addNavMenuItem(
        {
            id: 'customer-tickets',
            label: 'Customer Tickets',
            routerLink: ['/extensions/customer-support'],
            icon: 'inbox',
        },
        'marketing',
    ),
];
