import { NgModule } from '@angular/core';
import { addNavMenuItem } from '@vendure/admin-ui/core';

@NgModule({
    providers: [
        addNavMenuItem(
            {
                id: 'export-customers',
                label: 'Export customers',
                routerLink: ['/extensions/export-customers'],
                icon: 'download',
                requiresPermission: 'ExportCustomers',
            },
            'customers',
        ),
    ],
})
export class CustomerExportNavModule {}
