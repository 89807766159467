export const extensionRoutes = [  {
    path: 'extensions/invoices',
    loadChildren: () => import('./extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/invoices.module').then(m => m.InvoicesModule),
  },
  {
    path: 'extensions/invoice-list',
    loadChildren: () => import('./extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/routes'),
  },
  {
    path: 'extensions/export-products',
    loadChildren: () => import('./extensions/21fe828121eb48f39949013ce241a27f0bd6ccf733f45ed0bcfa256b8d7a5543/product-export.module').then(m => m.ProductExportModule),
  },
  {
    path: 'extensions/customer-support',
    loadChildren: () => import('./extensions/customer-support-ui/routes'),
  },
  {
    path: 'extensions/shipping-price',
    loadChildren: () => import('./extensions/shipping-price-ui/routes'),
  },
  {
    path: 'extensions/export-customers',
    loadChildren: () => import('./extensions/b0c039142fbb684972538b8e0165f18148f565d109d69a2380f0ad2299098b4b/customer-export.module').then(m => m.CustomerExportModule),
  }];
