import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesNavModule } from './extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/invoices-nav.module';
import { ProductExportNavModule } from './extensions/21fe828121eb48f39949013ce241a27f0bd6ccf733f45ed0bcfa256b8d7a5543/product-export-nav.module';
import { CustomerExportNavModule } from './extensions/b0c039142fbb684972538b8e0165f18148f565d109d69a2380f0ad2299098b4b/customer-export-nav.module';

import SharedProviders_0_0 from './extensions/0763c9e2a4af427b3eea647df0a109a9e2442e1568b387a6b4b1985efc131863/providers';
import SharedProviders_2_0 from './extensions/customer-support-ui/providers';
import SharedProviders_3_0 from './extensions/shipping-price-ui/providers';


@NgModule({
    imports: [CommonModule, InvoicesNavModule, ProductExportNavModule, CustomerExportNavModule],
    providers: [...SharedProviders_0_0, ...SharedProviders_2_0, ...SharedProviders_3_0],
})
export class SharedExtensionsModule {}
