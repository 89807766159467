import { NgModule } from '@angular/core';
import { addNavMenuItem, SharedModule } from '@vendure/admin-ui/core';

@NgModule({
    imports: [SharedModule],
    providers: [
        addNavMenuItem(
            {
                id: 'export-products',
                label: 'Export products',
                routerLink: ['/extensions/export-products'],
                icon: 'download',
                requiresPermission: 'ExportProducts',
            },
            'sales',
        ),
    ],
})
export class ProductExportNavModule {}
